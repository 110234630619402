import { useSelector } from 'react-redux';

// material-ui
import { styled } from '@mui/material/styles';
import { Card, CardContent } from '@mui/material';

import DetailEstate from 'components/DetailEstate';
import SearchResults from 'components/SearchResult';

const CardStyle = styled(Card)(({ theme }) => ({
    background: theme.palette.primary.light,
    marginBottom: '22px',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: '157px',
        height: '157px',
        background: theme.palette.primary[200],
        borderRadius: '50%',
        top: '-105px',
        right: '-96px'
    }
}));

const CardCustomStyle = styled(Card)(({ theme }) => ({
    background: '#fff',
    border: '1px solid',
    borderColor: theme.palette.primary.light,
    marginBottom: '22px',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: '157px',
        height: '157px',
        background: theme.palette.primary[200],
        borderRadius: '50%',
        top: '-105px',
        right: '-96px'
    }
}));

// ==============================|| SIDEBAR MENU Card ||============================== //

const MenuCard = () => {
    const state = useSelector((state) => state.geodata);

    return (
        <>
            <CardStyle>
                <CardContent sx={{ p: 2 }}>
                    <DetailEstate keyEstate={state.keyEstate} />
                </CardContent>
            </CardStyle>

            {state.search ? (
                <CardCustomStyle>
                    <CardContent sx={{ p: 2 }}>
                        <SearchResults search={state.search} />{' '}
                    </CardContent>
                </CardCustomStyle>
            ) : null}
        </>
    );
};

export default MenuCard;
