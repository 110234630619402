import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { SET_GEOJSON_FILTER_ESTATES } from 'store/actions';

const DispatchEstates = (props) => {
    const { estates } = props;
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({ type: SET_GEOJSON_FILTER_ESTATES, value: estates });
    }, [dispatch, estates]);
    return <></>;
};

DispatchEstates.propTypes = {
    estates: PropTypes.array
};

export default DispatchEstates;
