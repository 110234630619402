// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;
export const mapTypes = [
    {
        name: 'Open Street',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    },
    {
        name: 'Google Satellite',
        url: 'http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}&s=Ga'
    },
    {
        name: 'Google Maps',
        url: 'https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}'
    }
];

export const initialIndexMapType = 0;
