// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

// action - geodata
export const SET_KEY_ESTATE = '@geodata/SET_KEY_ESTATE';
export const SET_LOCATE = '@geodata/SET_LOCATE';
export const SET_MAP_TYPE = '@geodata/SET_MAP_TYPE';
export const SET_SEARCH = '@geodata/SET_SEARCH';
export const SET_LOCATION_ESTATE = '@geodata/SET_LOCATION_ESTATE';
export const SET_SELECTED_ESTATE = '@geodata/SET_SELECTED_ESTATE';
export const SET_GEOJSON_FILTER_ESTATES = '@geodata/SET_GEOJSON_FILTER_ESTATE';
export const SET_ZOOM = '@geodata/SET_ZOOM';
