import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SET_SEARCH, SET_GEOJSON_FILTER_ESTATES, SET_KEY_ESTATE, SET_SELECTED_ESTATE } from 'store/actions';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Card, Grid, InputAdornment, OutlinedInput, Popper, Typography } from '@mui/material';

// third-party
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state';

// project imports
import Transitions from 'ui-component/extended/Transitions';

// assets
import { IconSearch, IconX, IconArrowsMinimize } from '@tabler/icons';
import { shouldForwardProp } from '@mui/system';

// styles
const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
    zIndex: 1100,
    width: '99%',
    top: '-55px !important',
    padding: '0 12px',
    [theme.breakpoints.down('sm')]: {
        padding: '0 10px'
    }
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(({ theme }) => ({
    width: 434,
    marginLeft: 16,
    paddingLeft: 16,
    paddingRight: 16,
    '& input': {
        background: 'transparent !important',
        paddingLeft: '4px !important'
    },
    [theme.breakpoints.down('lg')]: {
        width: 250
    },
    [theme.breakpoints.down('md')]: {
        width: '100%',
        marginLeft: 4,
        background: '#fff'
    }
}));

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
    '&:hover': {
        background: theme.palette.secondary.dark,
        color: theme.palette.secondary.light
    }
}));

// ==============================|| SEARCH INPUT - MOBILE||============================== //

const MobileSearch = ({ value, buildValue, applySearch, flagSearch, popupState }) => {
    const theme = useTheme();
    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                applySearch(true);
            }}
        >
            <OutlineInputStyle
                id="input-search-header"
                value={value}
                onChange={(e) => buildValue(e.target.value)}
                placeholder="Buscar por Clave Catastral."
                endAdornment={
                    <InputAdornment position="end">
                        {flagSearch ? (
                            <ButtonBase sx={{ borderRadius: '12px', paddingLeft: '5px' }} onClick={() => applySearch(false)}>
                                <HeaderAvatarStyle variant="rounded">
                                    <IconX stroke={1.5} size="1.3rem" />
                                </HeaderAvatarStyle>
                            </ButtonBase>
                        ) : (
                            <ButtonBase sx={{ borderRadius: '12px' }} disabled={value.length < 10} onClick={() => applySearch(true)}>
                                <HeaderAvatarStyle variant="rounded">
                                    <IconSearch stroke={1.5} size="1.3rem" />
                                </HeaderAvatarStyle>
                            </ButtonBase>
                        )}
                        <Box sx={{ ml: 2 }}>
                            <ButtonBase sx={{ borderRadius: '12px' }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        ...theme.typography.commonAvatar,
                                        ...theme.typography.mediumAvatar,
                                        background: theme.palette.orange.light,
                                        color: theme.palette.orange.dark,
                                        '&:hover': {
                                            background: theme.palette.orange.dark,
                                            color: theme.palette.orange.light
                                        }
                                    }}
                                    {...bindToggle(popupState)}
                                >
                                    <IconArrowsMinimize stroke={1.5} size="1.3rem" />
                                </Avatar>
                            </ButtonBase>
                        </Box>
                    </InputAdornment>
                }
                aria-describedby="search-helper-text"
                inputProps={{ 'aria-label': 'weight' }}
            />
        </form>
    );
};

MobileSearch.propTypes = {
    value: PropTypes.string,
    buildValue: PropTypes.func,
    applySearch: PropTypes.func,
    flagSearch: PropTypes.bool,
    popupState: PopupState
};

// ==============================|| SEARCH INPUT ||============================== //

const SearchSection = () => {
    const [ciField, setCiField] = useState('');
    const [searching, setSearching] = useState(false);

    const theme = useTheme();
    const dispatch = useDispatch();

    const buildValue = (text) => {
        setCiField(text);
        if (text === '') {
            dispatch({ type: SET_SEARCH, value: null });
            dispatch({ type: SET_GEOJSON_FILTER_ESTATES, value: null });
            setSearching(false);
        }
    };

    const handleSearch = (applySearch) => {
        if (applySearch) {
            dispatch({ type: SET_GEOJSON_FILTER_ESTATES, value: null });
            dispatch({ type: SET_SELECTED_ESTATE, value: null });
            dispatch({ type: SET_SEARCH, value: { ciValue: ciField, birthdayValue: null } });
            setSearching(true);
        } else {
            setCiField('');
            dispatch({ type: SET_SEARCH, value: null });
            dispatch({ type: SET_GEOJSON_FILTER_ESTATES, value: null });
            dispatch({ type: SET_SELECTED_ESTATE, value: null });
            dispatch({ type: SET_KEY_ESTATE, value: null });
            setSearching(false);
        }
    };

    return (
        <>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <PopupState variant="popper" popupId="demo-popup-popper">
                    {(popupState) => (
                        <>
                            <Box sx={{ ml: 2 }}>
                                <ButtonBase sx={{ borderRadius: '12px' }}>
                                    <HeaderAvatarStyle variant="rounded" {...bindToggle(popupState)}>
                                        <IconSearch stroke={1.5} size="1.2rem" />
                                    </HeaderAvatarStyle>
                                </ButtonBase>
                            </Box>
                            <PopperStyle {...bindPopper(popupState)} transition>
                                {({ TransitionProps }) => (
                                    <>
                                        <Transitions type="zoom" {...TransitionProps} sx={{ transformOrigin: 'center left' }}>
                                            <Card
                                                sx={{
                                                    background: '#fff',
                                                    [theme.breakpoints.down('sm')]: {
                                                        border: 0,
                                                        boxShadow: 'none'
                                                    }
                                                }}
                                            >
                                                <Box sx={{ p: 2 }}>
                                                    <Grid container alignItems="center" justifyContent="space-between">
                                                        <Grid item xs>
                                                            <MobileSearch
                                                                value={ciField}
                                                                buildValue={buildValue}
                                                                applySearch={handleSearch}
                                                                flagSearch={searching}
                                                                popupState={popupState}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Card>
                                        </Transitions>
                                    </>
                                )}
                            </PopperStyle>
                        </>
                    )}
                </PopupState>
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSearch(true);
                    }}
                >
                    <OutlineInputStyle
                        id="input-search-header"
                        value={ciField}
                        placeholder="Buscar Clave Catastral."
                        onChange={(e) => buildValue(e.target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                {searching ? (
                                    <ButtonBase sx={{ borderRadius: '12px', paddingLeft: '5px' }} onClick={() => handleSearch(false)}>
                                        <HeaderAvatarStyle variant="rounded">
                                            <IconX stroke={1.5} size="1.3rem" />
                                        </HeaderAvatarStyle>
                                    </ButtonBase>
                                ) : (
                                    <ButtonBase
                                        sx={{ borderRadius: '12px' }}
                                        disabled={ciField.length < 10}
                                        onClick={() => handleSearch(true)}
                                    >
                                        <HeaderAvatarStyle variant="rounded">
                                            <IconSearch stroke={1.5} size="1.3rem" />
                                        </HeaderAvatarStyle>
                                    </ButtonBase>
                                )}
                            </InputAdornment>
                        }
                        aria-describedby="search-helper-text"
                        inputProps={{ 'aria-label': 'weight' }}
                    />
                </form>
            </Box>
        </>
    );
};

export default SearchSection;
