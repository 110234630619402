import PropTypes from 'prop-types';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';

// assets
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import { useQuery } from 'react-query';
import { getEsateByKey } from 'services/apiGeoportal';

const DetailEstate = (props) => {
    const { keyEstate } = props;

    const theme = useTheme();

    // get estates by key state
    const { data, isError, isLoading, isFetching } = useQuery(['server', keyEstate], () => getEsateByKey(keyEstate));
    if (isLoading) return <p>Cargando</p>;
    if (isFetching) return <p>Loading feaching...</p>;
    if (isError) return <p>error</p>;
    const dataEstate = data.results;

    return (
        <List sx={{ p: 0, m: 0 }}>
            <ListItem alignItems="flex-start" disableGutters sx={{ p: 0 }}>
                <ListItemAvatar sx={{ mt: 0 }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.largeAvatar,
                            color: theme.palette.primary.main,
                            border: ' none',
                            borderColor: theme.palette.primary.main,
                            background: '#fff',
                            marginRight: '8px'
                        }}
                    >
                        <TableChartOutlinedIcon fontSize="inherit" />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    sx={{ mt: 0 }}
                    primary={
                        <Typography variant="subtitle1" sx={{ color: theme.palette.primary[1800] }}>
                            INFORMACIÓN
                        </Typography>
                    }
                />
            </ListItem>

            {keyEstate ? (
                <>
                    <ListItemText
                        sx={{ mt: 0 }}
                        primary={
                            <Typography variant="subtitle1" sx={{ color: theme.palette.primary[1800] }}>
                                PROPIETARIO :
                            </Typography>
                        }
                        secondary={
                            <Typography variant="body2" sx={{ color: theme.palette.primary[1800] }}>
                                {dataEstate.owner}
                            </Typography>
                        }
                    />
                    <ListItemText
                        sx={{ mt: 0 }}
                        primary={
                            <Typography variant="subtitle1" sx={{ color: theme.palette.primary[1800] }}>
                                Clave Catastral:
                            </Typography>
                        }
                        secondary={
                            <Typography variant="body2" sx={{ color: theme.palette.primary[1800] }}>
                                {keyEstate}
                            </Typography>
                        }
                    />
                    <ListItemText
                        sx={{ mt: 0 }}
                        primary={
                            <Typography variant="subtitle1" sx={{ color: theme.palette.primary[1800] }}>
                                AREA(m2) :
                            </Typography>
                        }
                        secondary={
                            <Typography variant="body2" sx={{ color: theme.palette.primary[1800] }}>
                                {dataEstate.area_tag}
                            </Typography>
                        }
                    />
                    <ListItemText
                        sx={{ mt: 0 }}
                        primary={
                            <Typography variant="subtitle1" sx={{ color: theme.palette.primary[1800] }}>
                                CONSTRUCIÓN :
                            </Typography>
                        }
                        secondary={
                            <Typography variant="body2" sx={{ color: theme.palette.primary[1800] }}>
                                {dataEstate.exist_with}
                            </Typography>
                        }
                    />
                    <ListItemText
                        sx={{ mt: 0 }}
                        primary={
                            <Typography variant="subtitle1" sx={{ color: theme.palette.primary[1800] }}>
                                USO DE PREDIO :
                            </Typography>
                        }
                        secondary={
                            <Typography variant="body2" sx={{ color: theme.palette.primary[1800] }}>
                                {dataEstate.land_use}
                            </Typography>
                        }
                    />

                    <ListItemText
                        sx={{ mt: 0 }}
                        primary={
                            <Typography variant="subtitle1" sx={{ color: theme.palette.primary[1800] }}>
                                PERIMETRO :
                            </Typography>
                        }
                        secondary={
                            <Typography variant="body2" sx={{ color: theme.palette.primary[1800] }}>
                                {dataEstate.perimeter_tag}
                            </Typography>
                        }
                    />
                </>
            ) : (
                <>
                    <Typography variant="body2" sx={{ color: theme.palette.primary[1800] }}>
                        Para consultar información acerca de un predio.
                    </Typography>
                    <ul>
                        <li>Navegue en el mapa.</li>
                        <li>Encuentre su predio.</li>
                        <li>Click en el predio.</li>
                    </ul>
                    <Typography variant="caption" sx={{ color: theme.palette.primary[1800] }}>
                        * Nota: La ubicación de los predios en el mapa son referenciales.
                    </Typography>
                </>
            )}
        </List>
    );
};

DetailEstate.propTypes = {
    keyEstate: PropTypes.string
};

export default DetailEstate;
