import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import geodataReducer from './geodataReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    geodata: geodataReducer
});

export default reducer;
