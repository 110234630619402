const getPolygonEstate = (geometry) => {
    const dataPolygon = [];
    geometry.coordinates[0][0].forEach((item) => {
        dataPolygon.push([item[1], item[0]]);
    });

    return dataPolygon;
};

export default getPolygonEstate;
