import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const API_TOKEN_ACCESS = process.env.REACT_APP_API_TOKEN;

const axiosInstance = axios.create({ baseURL: API_URL || '' });

// Request interceptor for API calls
axiosInstance.interceptors.request.use(
    async (config) => {
        config.headers = {
            'x-api-key': API_TOKEN_ACCESS,
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        };
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

// Response interceptor for API calls
axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        // eslint-disable-next-line no-underscore-dangle
        if (error.response.status === 403 && !originalRequest._retry) {
            // eslint-disable-next-line no-underscore-dangle
            originalRequest._retry = true;
            axios.defaults.headers.common.Authorization = `Token ${API_TOKEN_ACCESS}`;
            return axiosInstance(originalRequest);
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
