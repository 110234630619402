import logoMorona from '../../../assets/images/logo-gmcm.png';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <a href="http://www.morona.gob.ec/" target="_blank" rel="noreferrer">
        <img src={logoMorona} alt="Gobierno Municipal del Cantón Morona" width="200" />
    </a>
);

export default LogoSection;
