import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { SET_LOCATION_ESTATE, SET_SELECTED_ESTATE, SET_ZOOM, SET_KEY_ESTATE } from 'store/actions';
import { useQuery } from 'react-query';
import { getEsatesBySearchValue } from 'services/apiGeoportal';

// material-ui
import { Alert, AlertTitle, Stack, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { IconMap2 } from '@tabler/icons';
import getPolygonEstate from 'utils/geo-data';
import DispatchEstates from './DispatchEstates';

const SearchResults = (props) => {
    const [expanded, setExpanded] = useState(true);

    const { search } = props;
    const dispatch = useDispatch();

    const { isLoading, isError, data, isFetching } = useQuery(['result_estates', search], () => getEsatesBySearchValue(search));
    if (isLoading) return <p>Cargando</p>;
    if (isFetching) return <p>Loading feaching...</p>;
    if (isError) return <p>error</p>;

    if (data.status === 204) {
        return (
            <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="info">
                    <AlertTitle>Atención</AlertTitle>
                    {data.message}
                </Alert>
            </Stack>
        );
    }

    if (data.status === 400) {
        return (
            <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {data.message}
                </Alert>
            </Stack>
        );
    }

    const estates = data.results;

    if (estates.length === 0) {
        return (
            <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="info">
                    <AlertTitle>Atención</AlertTitle>
                    No se ha encontrado predios asociados a {data.search}.
                </Alert>
            </Stack>
        );
    }

    const selectEstate = (estate) => {
        if (data.status === 200) {
            const geopos = [estate.geometry.coordinates[0][0][0][1], estate.geometry.coordinates[0][0][0][0]];
            const polygonEstate = getPolygonEstate(estate.geometry);
            const keyEstate = estate.properties.key_estate;

            dispatch({ type: SET_ZOOM, value: 18 });

            dispatch({
                type: SET_LOCATION_ESTATE,
                value: geopos
            });

            dispatch({
                type: SET_SELECTED_ESTATE,
                value: { polygon: polygonEstate, key: keyEstate }
            });
            dispatch({
                type: SET_KEY_ESTATE,
                value: keyEstate
            });
        }
    };

    return (
        <>
            <Typography variant="overline">Búsqueda</Typography>
            <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <IconMap2 stroke={1.5} size="1.2rem" style={{ marginRight: 5 }} />
                    <Typography>{data.total} resultados.</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ul className="estates_list">
                        {estates.map((estate, index) => (
                            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                            <li key={index} onClick={() => selectEstate(estate)}>
                                {estate.properties.key_estate}
                            </li>
                        ))}
                    </ul>
                </AccordionDetails>
            </Accordion>
            <DispatchEstates estates={estates} />
        </>
    );
};

SearchResults.propTypes = {
    search: PropTypes.element
};

export default SearchResults;
