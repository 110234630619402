import axiosInstance from 'utils/axios-client';

export const getEstates = async () => {
    const response = await axiosInstance.get('estates');
    return response.data;
};

export const getEsatesBySearchValue = async (value) => {
    if (value) {
        const response = await axiosInstance.get('estates/search', { params: { value: value.ciValue, birthday: value.birthdayValue } });
        return response.data;
    }
    return {};
};

export const getEsateByKey = async (keyEstate) => {
    if (keyEstate) {
        const response = await axiosInstance.get('estate', { params: { key_estate: keyEstate } });
        return response.data;
    }
    return {};
};
