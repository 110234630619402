// action - state management
import * as actionTypes from './actions';
import { initialIndexMapType, mapTypes } from './constant';

export const initialState = {
    keyEstate: null,
    locate: 0,
    mapType: mapTypes[initialIndexMapType],
    search: null,
    locationEstate: null,
    selectedEstate: null,
    geoJsonFilterEstates: null,
    zoom: null
};

// ==============================|| ESTATE REDUCER ||============================== //

const geodataReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_KEY_ESTATE:
            return {
                ...state,
                keyEstate: action.value
            };
        case actionTypes.SET_MAP_TYPE:
            return {
                ...state,
                mapType: action.value
            };
        case actionTypes.SET_LOCATE:
            return {
                ...state,
                locate: action.value
            };
        case actionTypes.SET_SEARCH:
            return {
                ...state,
                search: action.value
            };
        case actionTypes.SET_LOCATION_ESTATE:
            return {
                ...state,
                locationEstate: action.value
            };
        case actionTypes.SET_SELECTED_ESTATE:
            return {
                ...state,
                selectedEstate: action.value
            };
        case actionTypes.SET_GEOJSON_FILTER_ESTATES:
            return {
                ...state,
                geoJsonFilterEstates: action.value
            };
        case actionTypes.SET_ZOOM:
            return {
                ...state,
                zoom: action.value
            };
        default:
            return state;
    }
};

export default geodataReducer;
