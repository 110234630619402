import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';

import CancelIcon from '@mui/icons-material/Cancel';
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';

import { useDispatch } from 'react-redux';
import { SET_SEARCH, SET_GEOJSON_FILTER_ESTATES, SET_KEY_ESTATE, SET_SELECTED_ESTATE } from 'store/actions';

const FormDialog = () => {
    const theme = useTheme();
    const [ciField, setCiField] = useState('');
    const [ciFieldClose, setCiFieldClose] = useState('');
    const [birthdayField, setBirthdayField] = useState('');
    const [open, setOpen] = React.useState(false);
    const [searching, setSearching] = useState(false);

    const dispatch = useDispatch();

    const buildValue = (text) => {
        setCiField(text);
        if (text === '') {
            dispatch({ type: SET_SEARCH, value: null });
            dispatch({ type: SET_GEOJSON_FILTER_ESTATES, value: null });
            setSearching(false);
        }
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleSearch = (applySearch) => {
        if (applySearch) {
            dispatch({ type: SET_GEOJSON_FILTER_ESTATES, value: null });
            dispatch({ type: SET_SELECTED_ESTATE, value: null });
            dispatch({ type: SET_SEARCH, value: { ciValue: ciField, birthdayValue: birthdayField } });
            setSearching(true);
            setCiFieldClose(ciField);
            setCiField('');
            setBirthdayField('');
        } else {
            setCiFieldClose('ciField');
            setCiField('');
            setBirthdayField('');
            dispatch({ type: SET_SEARCH, value: null });
            dispatch({ type: SET_GEOJSON_FILTER_ESTATES, value: null });
            dispatch({ type: SET_SELECTED_ESTATE, value: null });
            dispatch({ type: SET_KEY_ESTATE, value: null });
            setSearching(false);
        }
        handleClose();
    };

    return (
        <div>
            {searching ? (
                <>
                    <Stack spacing={2} direction="row" alignItems="center">
                        <Typography variant="subtitle1" sx={{ color: theme.palette.primary[1800] }}>
                            Busqueda por :
                        </Typography>
                        <Button
                            variant="outlined"
                            size="large"
                            startIcon={<CancelIcon />}
                            color="error"
                            onClick={() => handleSearch(false)}
                        >
                            {ciFieldClose}
                        </Button>
                    </Stack>
                </>
            ) : (
                <>
                    <Button variant="outlined" startIcon={<SearchIcon />} onClick={handleClickOpen} color="primary" size="large">
                        Buscar por CI
                    </Button>
                </>
            )}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>CONSULTA DE PREDIOS POR NÚMERO DE CÉDULA</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="text"
                        label="Número de Cédula"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={ciField}
                        onChange={(e) => buildValue(e.target.value)}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="date"
                        label="Fecha de Nacimiento"
                        type="date"
                        fullWidth
                        variant="standard"
                        value={birthdayField}
                        onChange={(e) => setBirthdayField(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={() => handleSearch(true)}>Buscar</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default FormDialog;
